import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Game, GameService } from "../services/game.service";
import { Player, PlayerService } from "../services/player.service";
import { FakeAnswer, Question, QuestionService } from "../services/question.service";
import { Team, TeamService } from "../services/team.service";

@Component({
  selector: "app-game-lie",
  templateUrl: "./game-lie.component.html",
  styleUrls: ["./game-lie.component.scss"],
})
export class GameLieComponent implements OnInit, OnDestroy {
  private questions: Question[] = [];
  private teams: Team[] = [];
  private game: Game;
  public player: Player;
  public question: Question;
  public selected: string | undefined;

  public totalLying = 0;
  public lies: FakeAnswer[] = [];
  public isLying = true;
  public sent = false;
  public lieCtrl: FormControl = new FormControl("", [Validators.required, Validators.minLength(1)]);

  private destroy$ = new Subject<boolean>();
  private newQuestion$ = new Subject<boolean>();
  constructor(
    private questionService: QuestionService,
    private gameService: GameService,
    private playerService: PlayerService,
    private teamService: TeamService
  ) {}

  public ngOnInit(): void {
    this.questionService.questions$.pipe(takeUntil(this.destroy$)).subscribe(async (x) => {
      this.questions = x;
      await this.loadQuestions();
    });

    this.gameService.game$.pipe(takeUntil(this.destroy$)).subscribe(async (x) => {
      this.game = x;
      await this.loadQuestions();
    });

    this.teamService.teams$.pipe(takeUntil(this.destroy$)).subscribe(async (x) => {
      this.teams = x;
      await this.loadQuestions();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public async send(): Promise<void> {
    if (this.selected || !this.player || !this.isLying || this.lieCtrl.invalid) {
      return;
    }

    const answer: FakeAnswer = {
      value: (this.lieCtrl.value as string).toUpperCase(),
      team: this.player.team,
      questionId: this.question.id,
      player: this.player.name,
      picked: false,
    };
    await this.questionService.lie(answer);
  }

  private async loadQuestions(): Promise<void> {
    if (!this.game || !this.teams.length || !this.questions.length) {
      delete this.question;
      return;
    }

    if (!this.player) {
      this.player = await this.playerService.player$.pipe(take(1)).toPromise();
      if (!this.player) {
        return;
      }
    }

    const question = this.questions.find((q) => q.id === this.game.activeQuestionId);
    if (!question) {
      return;
    }

    // If it's a different question
    if (!this.question || this.question.id !== question.id) {
      this.question = question;
      this.newQuestion$.next(true);

      this.questionService
        .getFakesDocs(question)
        .pipe(takeUntil(this.newQuestion$))
        .subscribe(async (fakes: FakeAnswer[]) => {
          this.lies = fakes;
          this.selected = this.lies.find((x) => x.player === this.player.name)?.value;
        });

      const teamIndex = this.teamService.getPlayerTeamIndex(this.player);
      this.totalLying = this.playerService.countLyingPlayers(this.teams[teamIndex].name);
      this.isLying = this.game.activeTeamIndex !== teamIndex;
    }
  }
}
