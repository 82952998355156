import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  public error$ = new Subject<string>();
  constructor() {}
}
