<div class="card text-center" *ngIf="winner">
  <div class="card-header">Congratz to our winning team!</div>
  <div class="card-body">
    <h5 class="card-title">{{ winner.name }}</h5>
    <div class="card-text">
      <ng-container *ngFor="let point of winner.points">
        <footer class="blockquote-footer">
          {{ point.reason }} - {{ point.answer.player }} <cite title="Source Title">{{ point.amount }}</cite>
        </footer>
      </ng-container>
      <p>Total: {{ winnerPoints }} points</p>
    </div>
  </div>
  <div class="card-footer text-muted">This page will automatically change when a new game is started.</div>
</div>
