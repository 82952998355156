<div class="card text-center" *ngIf="question">
  <div class="card-header">{{ question.category }}</div>
  <div class="card-body">
    <p class="card-text font-weight-bold">{{ question.question }}</p>

    <ng-container *ngIf="isLying">
      <div class="form-group">
        <label for="inputLie"
          >Enter a lie:
          <input
            type="text"
            autocomplete="off"
            id="inputLie"
            class="form-control"
            maxlength="60"
            [formControl]="lieCtrl"
        /></label>
      </div>

      <div class="row">
        <div class="col text-center">
          <button
            class="btn btn-primary"
            (click)="send()"
            [attr.disabled]="lieCtrl.invalid ? lieCtrl.invalid : selected ? selected : undefined"
          >
            Send lie
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isLying">
      <div class="row">
        <div class="col text-center">
          <h3>Other teams are entering lies against you. Please wait adn get ready to answer...</h3>
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="card-footer">
    <span class="badge badge-secondary mr-2">Difficulty: {{ question.difficulty }}</span>
    <span class="badge badge-secondary">Lies: {{ lies.length }}/{{ totalLying }}</span>
  </div>
</div>
