import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Game, GameService } from "./services/game.service";
import { Player, PlayerService } from "./services/player.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private game: Game;
  private player: Player;

  constructor(private playerService: PlayerService, private gameService: GameService, private router: Router) {}

  public async ngOnInit(): Promise<void> {
    this.playerService.player$.subscribe(async (p) => {
      this.player = p;
      await this.onStateChange();
    });

    this.gameService.game$.subscribe(async (g) => {
      this.game = g;
      await this.onStateChange();
    });

    await this.playerService.autoLogin();
  }

  private async onStateChange(): Promise<boolean> {
    if (!this.player) {
      // No player, but we have a game (not started), go to register
      const discordId = this.playerService.getDiscordId();
      if (this.game && !this.game.started && discordId) {
        return this.router.navigate([discordId]);
      }
      return false;
    }

    if (this.game && !this.game.done) {
      // We have a game and a player, but it's not started, waiting for players.
      if (!this.game.started) {
        return this.router.navigate(["waiting-players"]);
      }

      // It's time to lie
      if (!this.game.lyingDone && this.game.activeQuestionId !== undefined) {
        return this.router.navigate(["game-lie"]);
      }

      return this.router.navigate(["game-answer"]);
    }

    return this.router.navigate(["game-end"]);
  }
}
