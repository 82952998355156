import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";

export interface Game {
  id?: string;
  started: boolean;
  done?: boolean;
  lyingDone?: boolean;
  activeTeamIndex?: number;
  activeQuestionId?: string;
}
@Injectable({
  providedIn: "root",
})
export class GameService {
  private gameBhv = new BehaviorSubject<Game>(undefined);
  public game$ = this.gameBhv.asObservable();

  constructor(private firestore: AngularFirestore) {
    this.firestore
      .collection<Game>("games")
      .valueChanges()
      .subscribe((games) => {
        this.gameBhv.next(games.length ? games[0] : undefined);
      });
  }
}
