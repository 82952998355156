import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErrorService } from "../services/error.service";
import { Game, GameService } from "../services/game.service";
import { PlayerService } from "../services/player.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  public game: Game;
  public error: string;

  public usernameCtrl: FormControl = new FormControl(localStorage.getItem("username"), [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(10),
  ]);
  private destroy$ = new Subject<boolean>();

  private discordId: string;

  constructor(
    private route: ActivatedRoute,
    private playerService: PlayerService,
    private gameService: GameService,
    private errorService: ErrorService
  ) {}

  public ngOnInit(): void {
    this.discordId = this.route.snapshot.paramMap.get("discordId");

    this.errorService.error$.pipe(takeUntil(this.destroy$)).subscribe((x) => (this.error = x));
    this.gameService.game$.pipe(takeUntil(this.destroy$)).subscribe((x) => (this.game = x));

    // if a change is made to our usernameCtrl, we remove displayed error.
    this.usernameCtrl.valueChanges.subscribe((value) => {
      // Prevent spaces (trim)
      this.usernameCtrl.patchValue(value.trim(), { emitEvent: false });
      delete this.error;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public async register(): Promise<void> {
    if (this.discordId && this.discordId !== "" && this.usernameCtrl.valid) {
      delete this.error;
      await this.playerService.register(this.discordId, this.usernameCtrl.value);
    }
  }
}
