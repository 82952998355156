import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";

export interface Point {
  reason: string;
  amount: number;
  answer: Answer;
}

export interface Answer {
  id?: string;
  value: string;
  questionId: string;
  team: string;
  player: string;
}

export interface FakeAnswer extends Answer {
  picked: boolean;
}

export interface Question {
  id?: string;
  category: string;
  type: string;
  difficulty: string;
  question: string;
  correct_answer: string;
  incorrect_answers: string[];
  doneAnswering: boolean;
  completed: boolean;
}

@Injectable({
  providedIn: "root",
})
export class QuestionService {
  private questionsBhv = new BehaviorSubject<Question[]>([]);
  public questions$ = this.questionsBhv.asObservable();

  constructor(private firestore: AngularFirestore) {
    this.firestore
      .collection<Question>("questions")
      .snapshotChanges()
      .subscribe((dataQuestions) => {
        const questions: Question[] = [];
        dataQuestions.forEach((data) => {
          const doc = data.payload.doc;
          const question = { ...doc.data(), id: doc.id };
          questions.push(question);
        });
        this.questionsBhv.next(questions);
      });
  }

  public async getAnswers(question: Question): Promise<Answer[]> {
    const snapshot = await this.firestore
      .collection<Answer>("answers", (ref) => ref.where("questionId", "==", question.id))
      .get()
      .toPromise();

    const answers: Answer[] = [];
    snapshot.forEach((doc) => {
      const answer = doc.data() as Answer;
      answers.push(answer);
    });
    return answers;
  }

  public getAnswersDocs(question: Question): Observable<any> {
    return this.firestore
      .collection<Answer>("answers", (ref) => ref.where("questionId", "==", question.id))
      .valueChanges();
  }

  public getFakesDocs(question: Question): Observable<any> {
    return this.firestore
      .collection<FakeAnswer>("lies", (ref) => ref.where("questionId", "==", question.id))
      .valueChanges();
  }

  public async getLies(question: Question): Promise<FakeAnswer[]> {
    const snapshot = await this.firestore
      .collection<FakeAnswer>("lies", (ref) => ref.where("questionId", "==", question.id))
      .get()
      .toPromise();
    const answers: FakeAnswer[] = [];
    snapshot.forEach((doc) => {
      const answer = doc.data() as FakeAnswer;
      answers.push(answer);
    });
    return answers;
  }

  public async answer(answer: Answer): Promise<void> {
    await this.firestore.collection<Answer>("answers").add(answer);
  }

  public async lie(answer: FakeAnswer): Promise<void> {
    await this.firestore.collection<FakeAnswer>("lies").add(answer);
  }
}
