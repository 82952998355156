<div class="card text-center" *ngIf="question && !question.completed">
  <div class="card-header">{{ question.category }}</div>
  <div class="card-body">
    <p class="card-text font-weight-bold">{{ question.question }}</p>

    <ng-container *ngIf="!question.doneAnswering">
      <ng-container *ngIf="!isVoting">
        <div class="row">
          <div class="col text-center">
            <small>{{ team.name }} team is voting. Please wait.</small><br />
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let option of options">
        <button
          type="button"
          class="btn m-2"
          [class.btn-primary]="isVoting"
          [class.btn-secondary]="!isVoting"
          [class.disabled]="!isVoting || (selected && selected !== option)"
          (click)="select(option)"
        >
          {{ option }}
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="question.doneAnswering">
      <div class="row">
        <div class="col text-center">
          <h3>Correct answer: {{ question.correct_answer }}</h3>
          <h4 *ngIf="correctAnswers().length">They got it right:</h4>
          <ng-container *ngFor="let answer of correctAnswers()">
            <p>{{ answer.player }}</p>
          </ng-container>
          <h4 *ngIf="fooledAnswers().length">They got fooled:</h4>
          <ng-container *ngFor="let answer of fooledAnswers()">
            <p>{{ answer.player }} > {{ answer.value }}</p>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="card-footer">
    <span class="badge badge-secondary mr-2">Difficulty: {{ question.difficulty }}</span>
    <span class="badge badge-secondary">Answered: {{ answers.length }}/{{ totalAnswering }}</span>
  </div>
</div>
