import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Team, TeamService } from "../services/team.service";

@Component({
  selector: "app-game-end",
  templateUrl: "./game-end.component.html",
  styleUrls: ["./game-end.component.scss"],
})
export class GameEndComponent implements OnInit, OnDestroy {
  private teams: Team[] = [];
  public winner: Team;
  public winnerPoints: number;

  private destroy$ = new Subject<boolean>();
  constructor(private teamService: TeamService) {}

  public ngOnInit(): void {
    this.teamService.teams$.pipe(takeUntil(this.destroy$)).subscribe((x) => {
      this.teams = x;
      if (this.teams.length) {
        const teamPoints = this.getTeamPoints(this.teams);
        this.winner = this.teams.find((t) => t.name === teamPoints[0].name);
        this.winnerPoints = teamPoints[0].total;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  private getTeamPoints(teams: Team[]): { name: string; total: number }[] {
    return teams
      .map((team) => {
        return { name: team.name, total: team.points.reduce((prev, cur) => prev + cur.amount, 0) };
      })
      .sort((a, b) => (a.total > b.total ? 1 : b.total > a.total ? -1 : 0))
      .reverse();
  }
}
