import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Player, PlayerService } from "../services/player.service";

@Component({
  selector: "app-waiting-players",
  templateUrl: "./waiting-players.component.html",
  styleUrls: ["./waiting-players.component.scss"],
})
export class WaitingPlayersComponent implements OnInit, OnDestroy {
  public player: Player;
  public players: string[] = [];

  private destroy$ = new Subject<boolean>();
  constructor(private playerService: PlayerService) {}

  public ngOnInit(): void {
    this.playerService.player$.pipe(takeUntil(this.destroy$)).subscribe((p) => (this.player = p));
    this.playerService.players$.pipe(takeUntil(this.destroy$)).subscribe((p) => (this.players = p.map((x) => x.name)));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
