import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { TimeAgoPipe } from "./pipes/timeago.pipe";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "../environments/environment";

import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RegisterComponent } from "./register/register.component";
import { WaitingPlayersComponent } from "./waiting-players/waiting-players.component";
import { GameLieComponent } from "./game-lie/game-lie.component";
import { GameAnswerComponent } from "./game-answer/game-answer.component";
import { GameEndComponent } from "./game-end/game-end.component";

@NgModule({
  declarations: [
    AppComponent,
    TimeAgoPipe,
    RegisterComponent,
    WaitingPlayersComponent,
    GameLieComponent,
    GameAnswerComponent,
    GameEndComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ReactiveFormsModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
