import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { GameAnswerComponent } from "./game-answer/game-answer.component";
import { GameEndComponent } from "./game-end/game-end.component";
import { GameLieComponent } from "./game-lie/game-lie.component";
import { RegisterComponent } from "./register/register.component";
import { WaitingPlayersComponent } from "./waiting-players/waiting-players.component";

const routes: Routes = [
  { path: "waiting-players", component: WaitingPlayersComponent },
  { path: "game-lie", component: GameLieComponent },
  { path: "game-answer", component: GameAnswerComponent },
  { path: "game-end", component: GameEndComponent },
  { path: ":discordId", component: RegisterComponent },
  { path: "", component: RegisterComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
