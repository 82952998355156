import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { Point } from "./question.service";
import { Player } from "./player.service";

export interface Team {
  id?: string;
  name: string;
  points: Point[];
}

@Injectable({
  providedIn: "root",
})
export class TeamService {
  private teamsBhv = new BehaviorSubject<Team[]>([]);
  public teams$ = this.teamsBhv.asObservable();

  constructor(private firestore: AngularFirestore) {
    this.firestore
      .collection<Team>("teams")
      .snapshotChanges()
      .subscribe((dataQ) => {
        const teams: Team[] = [];
        dataQ.forEach((data) => {
          const doc = data.payload.doc;
          teams.push({ ...doc.data(), id: doc.id });
        });
        this.teamsBhv.next(teams);
      });
  }

  public getPlayerTeamIndex(player: Player): number {
    const teams = this.teamsBhv.getValue();
    return teams.findIndex((t) => t.name === player.team);
  }
}
