import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { ErrorService } from "./error.service";
import { take } from "rxjs/operators";

export interface Player {
  discordId: string;
  name: string;
  fromWeb: boolean;
  team?: string;
}

@Injectable({
  providedIn: "root",
})
export class PlayerService {
  private playerBhv = new BehaviorSubject<Player>(undefined);
  public player$ = this.playerBhv.asObservable();

  public players$: Observable<Player[]>;
  private players: Player[] = [];

  constructor(private firestore: AngularFirestore, private errorService: ErrorService) {
    this.players$ = this.firestore.collection<Player>("players").valueChanges();
    this.players$.subscribe((x) => (this.players = x));
  }

  public async autoLogin(): Promise<void> {
    const username = localStorage.getItem("username");
    const discordId = this.getDiscordId();
    if (username && discordId) {
      this.firestore
        .collection<Player>("players", (ref) => ref.where("name", "==", username).limit(1))
        .valueChanges()
        .subscribe((players) => {
          if (players.length && players[0].discordId === discordId) {
            this.playerBhv.next(players[0]);
          } else {
            this.playerBhv.next(undefined);
          }
        });
    }
  }

  public getDiscordId(): string | null {
    return localStorage.getItem("discordId");
  }

  public async register(discordId: string, name: string): Promise<void> {
    if (name.trim() === "") {
      this.errorService.error$.next("Name cannot be empty.");
      return;
    }
    if (this.players.findIndex((x) => x.name === name) !== -1) {
      this.errorService.error$.next("Name already in use.");
      return;
    }

    const player: Player = {
      discordId,
      name,
      fromWeb: true,
    };
    const newPlayer = await this.firestore.collection("players").add(player);

    localStorage.setItem("discordId", discordId);
    localStorage.setItem("username", name);

    this.firestore
      .collection("players")
      .doc<Player>(newPlayer.id)
      .valueChanges()
      .subscribe((p) => this.playerBhv.next(p));

    return;
  }

  public countVotingPlayers(teamName: string): number {
    return this.players.filter((p) => p.team === teamName).length;
  }

  public countLyingPlayers(teamName: string): number {
    return this.players.filter((p) => p.team !== teamName).length;
  }
}
