// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  smsUrl: "http://localhost:5001/figaro-c233e/us-central1/sendSms",
  firebase: {
    apiKey: "AIzaSyAfCQNKjryVeSz8RRGIs8ykxg_MbC0VLf0",
    authDomain: "triviabot-54088.firebaseapp.com",
    databaseURL: "https://triviabot-54088.firebaseio.com",
    projectId: "triviabot-54088",
    storageBucket: "triviabot-54088.appspot.com",
    messagingSenderId: "675510496375",
    appId: "1:675510496375:web:29f826777cfd22c71daa72",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
