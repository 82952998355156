import { Pipe, PipeTransform, ChangeDetectorRef } from "@angular/core";
import TimeAgo from "javascript-time-ago";
import fr from "javascript-time-ago/locale/fr-CA";

@Pipe({ name: "timeAgo", pure: false })
export class TimeAgoPipe implements PipeTransform {
  public value: string;
  private timeAgo: TimeAgo;
  private timeout: any;

  constructor(private ref: ChangeDetectorRef) {
    TimeAgo.addLocale(fr);
    this.timeAgo = new TimeAgo("fr-CA");
  }

  public transform(timestamp: Date): string {
    const showTime = this.timeAgo.format(timestamp, "twitter");
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.ref.markForCheck();
    }, 1000);
    if (this.value && this.value === showTime) {
      return this.value;
    }
    this.value = showTime;
    return this.value;
  }
}
