<div class="form-group">
  <label for="inputUsername">Choose a username</label>
  <input
    type="text"
    autocomplete="off"
    id="inputUsername"
    class="form-control"
    maxlength="10"
    [formControl]="usernameCtrl"
  />
  <small class="text-danger" *ngIf="error">{{ error }}</small>
</div>

<div class="row">
  <div class="col text-center">
    <div class="alert alert-warning" role="alert" *ngIf="!game">
      There is no game available at the moment. You will be redirected automatically when a new game will be ready.
    </div>

    <button
      class="btn btn-primary"
      (click)="register()"
      *ngIf="game"
      [attr.disabled]="usernameCtrl.invalid ? usernameCtrl.invalid : undefined"
    >
      Register
    </button>
  </div>
</div>
