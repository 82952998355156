import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Game, GameService } from "../services/game.service";
import { Player, PlayerService } from "../services/player.service";
import { Answer, FakeAnswer, Question, QuestionService } from "../services/question.service";
import { Team, TeamService } from "../services/team.service";

@Component({
  selector: "app-game-answer",
  templateUrl: "./game-answer.component.html",
  styleUrls: ["./game-answer.component.scss"],
})
export class GameAnswerComponent implements OnInit, OnDestroy {
  private questions: Question[] = [];
  private teams: Team[] = [];
  private game: Game;
  private player: Player;
  public question: Question;

  public isVoting = true;
  public totalAnswering = 0;
  public options: string[] = [];
  public selected: string;

  public answers: Answer[] = [];
  public fakeAnswers: FakeAnswer[] = [];

  private destroy$ = new Subject<boolean>();
  private newQuestion$ = new Subject<boolean>();
  constructor(
    private questionService: QuestionService,
    private gameService: GameService,
    private playerService: PlayerService,
    private teamService: TeamService
  ) {}

  public ngOnInit(): void {
    this.questionService.questions$.pipe(takeUntil(this.destroy$)).subscribe(async (x) => {
      this.questions = x;
      await this.loadQuestions();
    });

    this.gameService.game$.pipe(takeUntil(this.destroy$)).subscribe(async (x) => {
      this.game = x;
      await this.loadQuestions();
    });

    this.teamService.teams$.pipe(takeUntil(this.destroy$)).subscribe(async (x) => {
      this.teams = x;
      await this.loadQuestions();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public get team(): Team {
    return this.teams[this.game?.activeTeamIndex];
  }

  public async select(value: string): Promise<void> {
    if (this.selected || !this.player || !this.isVoting) {
      return;
    }

    const answer: Answer = {
      value,
      team: this.player.team,
      questionId: this.question.id,
      player: this.player.name,
    };
    await this.questionService.answer(answer);
  }

  private async loadQuestions(): Promise<void> {
    if (!this.game || !this.teams.length || !this.questions.length) {
      delete this.question;
      return;
    }

    if (!this.player) {
      this.player = await this.playerService.player$.pipe(take(1)).toPromise();
      if (!this.player) {
        return;
      }
    }

    const question = this.questions.find((q) => q.id === this.game.activeQuestionId);
    if (!question) {
      this.options = [];
      return;
    }
    if (question?.id === this.question?.id) {
      this.question = question;
    }

    // If it's a different question
    if (!this.question || this.question.id !== question.id) {
      this.question = question;
      this.newQuestion$.next(true);

      this.fakeAnswers = (await this.questionService.getLies(this.question)).filter((f) => f.picked);

      this.questionService
        .getAnswersDocs(question)
        .pipe(takeUntil(this.newQuestion$))
        .subscribe(async (answers: Answer[]) => {
          this.answers = answers;
          this.selected = this.answers.find((x) => x.player === this.player.name)?.value;
        });

      const teamIndex = this.teamService.getPlayerTeamIndex(this.player);
      this.totalAnswering = this.playerService.countVotingPlayers(this.teams[teamIndex].name);
      this.isVoting = this.game.activeTeamIndex === teamIndex;

      const opts = [
        ...this.question.incorrect_answers,
        this.question.correct_answer,
        ...this.fakeAnswers.map((f) => f.value),
      ];
      this.options = this.shuffle(Array.from(new Set(opts)));
    }
  }

  public correctAnswers(): Answer[] {
    return this.answers.filter((a) => a.value === this.question.correct_answer);
  }

  public fooledAnswers(): Answer[] {
    return this.answers.filter((a) => this.fakeAnswers.map((x) => x.value).indexOf(a.value) !== -1);
  }

  private shuffle<T>(a: T[]): T[] {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
}
